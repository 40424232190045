import { Skeleton, styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";
import { Column } from "../MobileApp/styles";

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4vw 0 1vw 0;
  box-sizing: border-box;

  ${breakpoint(
    "medium-",
    `
      padding: 1vw;
    `
  )}
`;

export const SkeletonMainContainer = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 4vw 0 1vw 0;
  box-sizing: border-box;

  ${breakpoint(
    "medium-",
    `
      padding: 1vw;
    `
  )}
`;

export const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  left: 5%;
  max-width: 40%;
  z-index: 10;
  padding: 1rem 1rem 5rem;
  color: white;
  min-height: 10rem;

  ${breakpoint(
    "medium-",
    `
        max-width: 80%;
        width: 80%;
        padding: 0;
        min-height: 5rem;
    ` 
  )}
`;

export const SkeletonHeaderCont = styled(Skeleton)`
  display: flex;
  flex-direction: column;
  left: 5%;
  max-width: 40%;
  z-index: 10;
  padding: 1rem 1rem 5rem;
  color: white;
  min-height: 10rem;

  ${breakpoint(
    "medium-",
    `
        max-width: 80%;
        width: 80%;
        padding: 0;
        min-height: 5rem;
    ` 
  )}
`;

export const Row1 = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: fit-content;
  padding: 0 0 0 4vw;
  min-height: 20rem;

  ${breakpoint(
    "medium-",
    `
        flex-direction: column;
        height: auto;
        padding: 1vw;
        min-height: 10rem;
    `
  )}
`;

export const SkeletonRow1 = styled(Skeleton)`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  height: fit-content;
  padding: 0 0 0 4vw;
  min-height: 20rem;

  ${breakpoint(
    "medium-",
    `
        flex-direction: column;
        height: auto;
        padding: 1vw;
        min-height: 10rem;
    `
  )}
`;

export const Graphic1Wrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 15px;
  top: 0;
  width: 75%;
  height: 100%;
  z-index: 5;

  ${breakpoint(
    "xxlarge+",
    `
        justify-content: center;
    `
  )}

  ${breakpoint(
    "medium-",
    `
        width: 85%;
        position: relative;
        padding: 5vw 0;
    `
  )}
`;

export const Graphic1 = styled("img")`
  width: 100%;
  height: auto;
  object-fit: cover;

  ${breakpoint(
    "medium-",
    `
        margin: 10px 0 20px 0;
    `
  )}
`;
export const FullWidthGraphicContainer = styled("div")`
  position: relative;
  width: 100%;
  padding: 0 4vw;
  box-sizing: border-box;
  min-height: 30rem;
`;

export const SkeletonFullWidthGraphicContainer = styled(Skeleton)`
  position: relative;
  width: 100%;
  padding: 0 4vw;
  box-sizing: border-box;
  min-height: 30rem;
`;

export const WebGraphic2 = styled("img")`
  width: 80%;
  height: auto;
  object-fit: cover;
  flex-grow: 1;
`;

export const FullWidthGraphic = styled("img")`
  width: 100%;
  height: auto;
  z-index: 1;
  position: relative;
  top: -9vw;
  min-height: 30rem;

  ${breakpoint(
    "medium-",
    `
      top: -10vw;
    ` 
  )}
`;

export const SignUpSection = styled("div")`
  position: absolute;
  bottom: 17vw;
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 15;
  text-align: center;
  width: 100%;
  max-width: 100%;
  color: white;

  ${breakpoint(
    "medium-",
    `
        position: relative;
        width: 100%;
        max-width: 100%;
        top: -130px;
    `
  )}
`;

export const Subheader = styled("h2")`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: white;
  padding: 0.5vw;
`;

export const EmailSignUpWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  flex: 1;
`;

export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2vw;
  box-sizing: border-box;
  height: auto;
  padding: 0 4vw;

  ${breakpoint(
    "medium-",
    `
        flex-direction: column;
        gap: 0.5vw;
        align-items: flex-start;
    `
  )}
`;

export const WhiteSubSectionHeader = styled("span")`
  color: #f6f6f6;
  font-family: Helvetica;
  font-size: 1.5vw;
  width: 100%;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${breakpoint(
    "medium-",
    `
        font-size: 14px;
        text-align: center;
        margin: 0.5vw 0;
    `
  )}
`;

export const FullWidthGraphic2 = styled("img")`
  width: 100%;
  height: auto;
  z-index: 1;
  position: relative;
  padding: 4vw 0;
`;

export const WebGraphicWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  justify-content: center;

  ${breakpoint(
    "medium-",
    `
        justify-content: center;
        width: 100%;
    `
  )}
`;

export const WebTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  flex: 1;
`;

export const WebHeaderLarge = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 7vw;
  text-transform: uppercase;
  white-space: nowrap;

  ${breakpoint(
    "xxxlarge+",
    `
        font-size: 7vw;
    `
  )}

  ${breakpoint(
    "medium-",
    `
        text-align: center;
        font-size: 26px;
    `
  )}
`;

export const WebGraphic = styled("img")`
  width: 100%;
  height: auto;
  object-fit: cover;
  flex-grow: 1;
`;

export const WebColumn = styled("div")`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;
  justify-content: space-between;

  ${breakpoint(
    "medium-",
    `
        width: 100%;
    `
  )}
`;

export const TextRow = styled("div")`
  position: relative;
  top: -9vw;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 2vw;
  box-sizing: border-box;
  height: auto;
  padding: 0 4vw;

  ${breakpoint(
    "medium-",
    `
        flex-direction: column;
        gap: 0.5vw;
        align-items: flex-start;
    `
  )}
`;
